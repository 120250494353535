// Api Header
import { getConfig, postConfig } from "./apiHeader";
// Api URL
import {
  PocketClaim,
  PointList,
  PointWarning,
  postPointURL,
  PromoClaim,
  PromoMaintain,
} from "./apiUrl";

// export async function getPromotionApi() {
//     const response = await fetch(GetPromotionURL, getConfig);
//     if (response.ok) return await response.json();
//     else throw response;
// }

//Promotion
export async function isPromoMaintain() {
  const response = await fetch(PromoMaintain, getConfig);
  if (response.ok) return await response.json();
  else throw response;
}

export async function claimPromotion({ token }) {
  const response = await fetch(PromoClaim, postConfig(token));
  if (response.ok) return await response.json();
  else throw response;
}

//Point
export async function getPointList() {
  const response = await fetch(PointList, getConfig);
  if (response.ok) return await response.json();
  else throw response;
}

export async function getPointWarning() {
  const response = await fetch(PointWarning, getConfig);
  if (response.ok) return await response.json();
  else throw response;
}

export const postPoint = async ({ token, point_id }) => {
  const res = await fetch(postPointURL, {
    ...postConfig(token),
    body: JSON.stringify({ point_id }),
  });
  if (res.ok) return res.json();
  else throw res;
};

//Pocket
export async function claimPocket({ token }) {
  const response = await fetch(PocketClaim, postConfig(token));
  if (response.ok) return await response.json();
  else throw response;
}
